import './App.scss';
import { Header, Footer } from './Structure';

function App() {
  return (
	<div id="wrapper">
		<Header />
		<main id="main">
			<p>
				Handelsnamen van Renselaar Concepts B.V. zijn: <br />
				<ul>
					<li><a href="https://codely.nl" target="_blank" rel="noreferrer">Codely</a></li>
					<li><a href="https://underdock.studio" target="_blank" rel="noreferrer">Underdock</a></li>
				</ul>
			</p>
		</main>
		<Footer />
	</div>
  );
}

export default App;
