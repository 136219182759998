function Header() {
	return (
		<header id="header">
			<h3 className="text-3xl font-bold underline">Renselaar Concepts B.V.</h3>
		</header>
	);
}

function Footer() {
	  return (
		<footer id="footer">
			<div class="col">
				<p>Bovenweg 69<br/>8421DC Oldeberkoop</p>
			</div>
			<div class="col">
				<p>E-mail: <a href="mailto:info@renselaar.net">info@renselaar.net</a><br/>KVK: 78751659</p>
			</div>
		</footer>
 	 );
}

export {Header, Footer};